// ** @jsx jsx *
import React from "react"
import { IoMdClose } from "react-icons/io"
import { jsx, useThemeUI } from "theme-ui"
import M from "react-modal"
M.setAppElement(`#___gatsby`)
const Modal = ({
  children,
  open,
  onClose,
  closeIcon,
  justifyContent = "center",
  maxWidth,
}) => {
  const _theme = useThemeUI().theme
  return (
    <M
      style={{
        overlay: {
          backgroundColor:
            _theme && _theme.colors && _theme.colors.backgroundOpacity,
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: justifyContent,
          zIndex: 10,
          padding: "8px",
          // paddingLeft: 20,
          // paddingRight: 20,
        },
        content: {
          position: "static",
          animation: "animationTop 0.5s",
          width: "85vw",
          maxWidth: maxWidth || 350,
          // bottom: "auto",
          maxHeight: "90vh",

          backgroundColor:
            _theme && _theme.colors && _theme.colors.backgroundSecondary,
        },
      }}
      isOpen={open}
      onRequestClose={onClose}
    >
      {closeIcon && (
        <div
          variant="div.flex"
          sx={{ width: "100%", justifyContent: "flex-end" }}
        >
          <div
            className="pointer"
            onClick={onClose}
            sx={{ textAlign: "end", width: "fit-content", marginLeft: "auto" }}
          >
            <IoMdClose />
          </div>
        </div>
      )}
      {children}
    </M>
  )
}

export default Modal
