// ** @jsx jsx *
import React from "react"
import { jsx } from "theme-ui"
import Img from "gatsby-image"
const MiniImages = ({ cart }) => {
  let images = []
  if (cart) {
    if (cart.length > 0) {
      cart.forEach(product => {
        if (product) {
          product.order.forEach(variant => {
            if (!variant.hiddenExtra) {
              images.push(variant.img)
            }
          })
        }
      })
    }
  }

  return (
    <>
      {cart &&
        images.length > 0 &&
        images.reverse().map((img, i) => {
          return (
            img &&
            img.asset &&
            img.asset.fixed && (
              <div
                key={i}
                sx={{
                  // backgroundColor: "background",
                  // border: "simple",
                  borderRadius: 1,
                  marginLeft: 1,
                  marginRight: 1,
                }}
              >
                <Img fixed={img.asset.fixed} sx={{ borderRadius: 1 }} />
              </div>
            )
          )
        })}
    </>
  )
}

export default MiniImages
