const fade = (hex, opacity) => {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},${
      opacity || 1
    })`
  }
  throw new Error("Bad Hex")
}
function RGBAToHexA(rgba) {
  let sep = rgba.indexOf(",") > -1 ? "," : " "
  rgba = rgba.substr(5).split(")")[0].split(sep)

  // Strip the slash if using space-separated syntax
  if (rgba.indexOf("/") > -1) rgba.splice(3, 1)

  for (let R in rgba) {
    let r = rgba[R]
    if (r.indexOf("%") > -1) {
      let p = r.substr(0, r.length - 1) / 100

      if (R < 3) {
        rgba[R] = Math.round(p * 255)
      } else {
        rgba[R] = p
      }
    }
  }
  let r = (+rgba[0]).toString(16),
    g = (+rgba[1]).toString(16),
    b = (+rgba[2]).toString(16),
    a = Math.round(+rgba[3] * 255).toString(16)

  if (r.length == 1) r = "0" + r
  if (g.length == 1) g = "0" + g
  if (b.length == 1) b = "0" + b
  if (a.length == 1) a = "0" + a

  return "#" + r + g + b
}
function LightenDarkenColor(col, amt) {
  var usePound = false

  if (col[0] == "#") {
    col = col.slice(1)
    usePound = true
  }

  var num = parseInt(col, 16)

  var r = (num >> 16) + amt

  if (r > 255) r = 255
  else if (r < 0) r = 0

  var b = ((num >> 8) & 0x00ff) + amt

  if (b > 255) b = 255
  else if (b < 0) b = 0

  var g = (num & 0x0000ff) + amt

  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16)
}
// import mix from "mix-color"
export default colorsProp => {
  const mix = require("mix-color").default
  const black = (opacity = 1) => `rgba(0,0,0,${opacity})`
  const white = (opacity = 1) => `rgba(255,255,255,${opacity})`

  let whatsappColors = {
    whatsappBackground: "#25d366",
    whatsappBackgroundSecondary: "#128c7e",
    whatsappText: "#ece5dd",
  }

  // let colorsDark = {
  //   theme: "dark",
  //   colorPrimary: "#80fff7",
  //   colorSecondary: "#c91d6a",
  //   background: "#2a2f36",
  // }
  // let colorsLight = {
  //   theme: "light",
  //   colorPrimary: "#128c7e",
  //   colorSecondary: "#bb243a",
  //   background: "#ededed",
  // }
  let colorsDark = {
    theme: "dark",
    colorPrimary: "#80fff7",
    colorSecondary: "#c91d6a",
    background: "#292929",
  }
  let colorsLight = {
    theme: "light",
    colorPrimary: "#128c7e",
    colorSecondary: "#bb243a",
    background: "#ededed",
  }
  let colorsDefault = colorsDark
  let colors = colorsDefault
  if (colorsProp !== "none") {
    const _colors = colorsProp.theme == "dark" ? colorsDark : colorsLight
    colors = {
      theme: colorsProp.theme || _colors.theme,
      colorPrimary: colorsProp.colorPrimary || _colors.colorPrimary,
      colorSecondary: colorsProp.colorSecondary || _colors.colorSecondary,
      background: colorsProp.background || _colors.background,
    }
  }
  if (colorsProp == "dark") {
    colors = colorsDark
  }
  if (colorsProp == "light") {
    colors = colorsLight
  }
  const newBackground = RGBAToHexA(
    mix(colors.background, colors.colorPrimary, 0.025)
  )
  const neu = (size, pressed, intensity = 20, color = newBackground) =>
    `${pressed ? "inset" : ""} ${size}px ${size}px ${
      size * 2
    }px ${LightenDarkenColor(color, -intensity)}, ${
      pressed ? "inset" : ""
    } -${size}px -${size}px ${size * 2}px ${LightenDarkenColor(
      color,
      intensity
    )}`
  return {
    fonts: {
      body: '"Montserrat"',
      heading: '"Roboto"',
      monospace: "Menlo, monospace",
    },

    colors: {
      text: colors.theme == "light" ? black() : white(),
      textSecondary: white(),
      textBlur: colors.theme == "light" ? black(0.7) : white(0.9),
      // background: colors.background,
      background: newBackground,
      backgroundOpacity: "rgba(0, 0, 0, 0.9)",
      backgroundDarken: LightenDarkenColor(newBackground, -10),
      backgroundLighten: LightenDarkenColor(newBackground, 10),
      backgroundSecondary:
        colors.theme == "light"
          ? white()
          : LightenDarkenColor(newBackground, -10),
      soft: colors.theme == "light" ? white(0.3) : white(0.05),
      primary: colors.colorPrimary,
      secondary: colors.colorSecondary,
      blur: colors.theme == "light" ? white(1) : black(0.3),
      whatsappBackground: whatsappColors.whatsappBackground,
      whatsappBackgroundSecondary: whatsappColors.whatsappBackgroundSecondary,
      whatsappText: whatsappColors.whatsappText,
    },

    radii: [4, 8, 16],
    borders: {
      simple: `2px solid ${colors.theme == "light" ? black() : white()}`,
      backgroundSimple: `2px solid ${newBackground}`,
      opacity: `2px solid ${colors.theme == "light" ? black(0.1) : white(0.1)}`,
      primarySimple: `2px solid ${colors.colorPrimary}`,
      secondary: `2px solid ${colors.colorSecondary}`,
    },
    shadows: {
      shadowInset: `inset 0px -2px 12px 1px ${LightenDarkenColor(
        newBackground,
        -15
      )}`,

      flat: neu(9),
      flatSmall: colors.theme == "dark" ? neu(4, false, 20) : neu(4, false, 30),
      flatPrimary: neu(4.5, false, 15, colors.colorPrimary),

      pressed: neu(9, true),
      pressedSmall:
        colors.theme == "dark" ? neu(4, true, 20) : neu(4, true, 30),
      pressedPrimary: neu(4.5, true, 15, colors.colorPrimary),

      pressedWhatsapp: neu(
        9,
        true,
        15,
        whatsappColors.whatsappBackgroundSecondary
      ),
      flatWhatsapp: neu(
        9,
        false,
        15,
        whatsappColors.whatsappBackgroundSecondary
      ),

      flatLight: `-4px -4px 7px ${LightenDarkenColor(newBackground, 20)}`,
      flatDark: `4px 4px 4px ${LightenDarkenColor(newBackground, -20)}`,
    },
    sizes: {
      width1: "95vw",
      width2: "500px",
      width3: "66.6vw",
      halfWidth1: "42.5vw",
      halfWidth2: "250px",
      halfWidth3: "33.3vw",
    },

    styles: {
      div: {},
      h1: {
        fontFamily: "body",
        color: "primary",
        fontSize: 4,
      },
      h2: {
        fontFamily: "heading",
        color: "primary",
        fontSize: 2,
        margin: 0,
      },
      h3: {
        fontFamily: "body",
        color: "textBlur",
        fontSize: 0,
      },
      h4: {
        fontFamily: "body",
        color: "textBlur",
        fontSize: 0,
      },
      h5: {
        fontFamily: "body",
        color: "textBlur",
        fontSize: 0,
        padding: 0,
        margin: 0,
      },
      h6: {
        fontFamily: "body",
        color: "textBlur",
        fontWeight: "initial",
        fontSize: 0,
        margin: 0,
      },
      li: {
        fontFamily: "body",
        color: "text",
        "*": {
          fontFamily: "body",
        },
      },
      a: {
        fontFamily: "body",
        color: "primary",
        cursor: "pointer",
      },
      p: {
        fontFamily: "body",
        color: "textBlur",
        fontSize: 0,
      },
      blockquote: {
        fontFamily: "body",
        color: "text",
      },
    },

    text: {
      title: {
        fontFamily: "body",
        color: "text",
        fontSize: 3,
      },
      primaryInvert: {
        fontFamily: "body",
        color: "background",
      },
      primary: {
        fontFamily: "body",
        color: "text",
      },
      caption: {
        fontFamily: "body",
        color: "text",
        fontSize: 0,
        fontWeight: "bold",
        textTransform: "uppercase",
      },
      body: {
        fontFamily: "body",
        fontWeight: "normal",
        color: "text",
        fontSize: 0,
      },
      tag: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "body",
        backgroundColor: "background",
        border: "opacity",
        borderRadius: 2,
        padding: 2,
        paddingTop: 1,
        paddingBottom: 1,
        fontSize: 1,
        fontWeight: "bold",
        color: "text",
      },
      small: {
        fontFamily: "monospace",
        fontSize: 0,
      },
    },
    buttons: {
      default: {
        "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
        fontFamily: "body",
        borderRadius: 1,
        outline: "transparent",
        transition: "0.2s",
        "&:active": {
          transform: "scale(0.98)",
        },
      },
      primary: {
        variant: "buttons.default",
        backgroundColor: "primary",
        color: "background",
      },
      secondary: {
        variant: "buttons.default",
        backgroundColor: "secondary",
        color: "textSecondary",
      },
      outlined: {
        variant: "buttons.default",
        border: "opacity",
        backgroundColor: "background",
        color: "text",
      },
      primaryFlat: {
        variant: "buttons.default",
        backgroundColor: "background",
        boxShadow: "flat",
        color: "primary",
        "&:active": {
          boxShadow: "pressed",
        },
      },
      whatsapp: {
        variant: "buttons.default",
        backgroundColor: "whatsappBackground",
        color: "whatsappText",
        fontWeight: "bold",
      },
    },
    badges: {
      primary: {
        color: "textSecondary",
        backgroundColor: "secondary",
        fontFamily: "monospace",
      },
      secondary: { color: "textSecondary", backgroundColor: "secondary" },
      invert: { color: "background", backgroundColor: "text" },
    },

    layout: {
      flat: {
        backgroundColor: "background",
        boxShadow: "flat",
        borderRadius: 2,
      },
    },
    div: {
      flat: {
        backgroundColor: "background",
        boxShadow: "flat",
        borderRadius: 1,
      },
      flex: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      flexWrap: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      flexColumn: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      },
    },
    messages: {
      primary: {
        variant: "text.primaryInvert",
        // backgroundColor: "primary",
        color: "background",
      },
    },
    forms: {
      input: {
        border: "opacity",
        boxShadow: `inset 4px 4px 10px ${LightenDarkenColor(
          newBackground,
          -25
        )}, inset -4px -4px 10px ${LightenDarkenColor(newBackground, 25)}`,
        transition: "0.2s",
        "&:focus": {
          borderColor: "transparent",
          outline: "none",
          backgroundColor: fade(colors.colorPrimary, 0.1),
          boxShadow: `inset 2px 2px 7px ${LightenDarkenColor(
            newBackground,
            -29
          )}, inset -2px -2px 7px ${LightenDarkenColor(newBackground, 29)}`,
        },
      },
      select: {
        paddingRight: 20,
        border: 0,
        boxShadow: "flat",
        "& option": {
          backgroundColor: "background",
          color: "text",
        },
        "& option:hover": {
          backgroundColor: "primary",
          color: "background",
        },
        "& option:hover": {
          backgroundColor: "primary",
          color: "background",
        },
        "& option:active": {
          backgroundColor: "primary",
          color: "background",
        },
        "&:focus": {
          borderColor: "transparent",
          outline: "none",
          boxShadow: `inset 2px 2px 7px ${LightenDarkenColor(
            newBackground,
            -29
          )}, inset -2px -2px 7px ${LightenDarkenColor(newBackground, 29)}`,
        },
      },
      checkbox: {
        margin: 0,
        "&:focus": {
          borderColor: "transparent",
          outline: "none",
          backgroundColor: "transparent",
        },
        "& svg": {
          color: "transparent !important",
        },
      },
    },
  }
}
